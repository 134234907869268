export const deviceLogActionConstants = {
  ACTIVATED: 'ACTIVATED',
  ASSIGNED: 'ASSIGNED',
  EXPIRED: 'EXPIRED',
  INITIALIZED: 'INITIALIZED',
  REMOVED: 'REMOVED',
  SHIPPED: 'SHIPPED',
  UPDATED: 'UPDATED',
};

export const deviceLogActionDictionary = [
  { value: deviceLogActionConstants.ACTIVATED, label: 'Activated by Customer' },
  { value: deviceLogActionConstants.ASSIGNED, label: 'Assigned to Vehicle' },
  { value: deviceLogActionConstants.EXPIRED, label: 'Subscription Expired' },
  { value: deviceLogActionConstants.INITIALIZED, label: 'Initialized with Subscription Order' },
  { value: deviceLogActionConstants.REMOVED, label: 'Removed from Vehicle' },
  { value: deviceLogActionConstants.SHIPPED, label: 'Production Scan (Shipped)' },
  { value: deviceLogActionConstants.UPDATED, label: 'Subscription Updated' },
];
