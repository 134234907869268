import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { checkLogin } from 'legacy/shared/v1/utilities/user';

import { apiCall } from 'legacy/core/api/API';
import {
  mapMaintenanceDataToFrontend,
  mapMaintenanceTotalMilageData,
} from 'legacy/features/maintenance/utilities/mapping';

const getMaintenanceApi = async ({ organizationId }) => {
  const { body } = await apiCall({
    path: `/maintenance`,
    method: 'get',
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  return body;
};

export const useFetchScheduledMaintenanceEventsQuery = ({ key = '', autoRun = true } = {}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const user = useSelector((state) => state.user);
  const scheduledMaintenanceItemsQuery = useQuery({
    queryKey: ['fetchScheduledMaintenanceEventsQuery' + key],
    queryFn: () => getMaintenanceApi({ organizationId: filteredOrganizationId }),
    enabled: autoRun && checkLogin(),
    onError: (err) => {
      logWcpError(err);
    },
    select: (body) => {
      return body.message.events
        ?.map((item) => mapMaintenanceDataToFrontend(item))
        .map((e) => mapMaintenanceTotalMilageData({ maintenanceEvent: e }));
    },
  });

  return scheduledMaintenanceItemsQuery;
};
