import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';

import { setFirmwareAlertCount, toggleSidebarAlerts } from 'legacy/core/redux/ui/actions';
import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';
import { maintenanceTypeDropdownData } from 'legacy/features/maintenance/data/maintenanceTypeDropdownData';
import { useFetchScheduledMaintenanceEventsQuery } from 'legacy/features/maintenance/hooks/queries/useFetchScheduledMaintenanceEventsQuery';
import { mapMaintenanceDataToFrontend } from 'legacy/features/maintenance/utilities/mapping';
import useFetchFirmwareQuery from 'legacy/features/firmware/hooks/useFetchFirmwareQuery';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { getOrganization } from 'legacy/core/redux/organizations/selectors';
import { checkForUserPermission } from 'legacy/core/redux/user/selectors';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { checkLogin } from 'legacy/shared/v1/utilities/user';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';
import {
  Wrapper,
  Head,
  Title,
  Body,
  List,
  MessageWrapper,
  AlertLink,
  ListItem,
  MaintenanceItem,
  DetailDiv,
  IconWrapper,
  AlertTitleWrapper,
} from 'legacy/shared/v1/styles/components/SidebarAlerts';
import { v4 as uuid } from 'uuid';

const SidebarAlertsNotifier = ({ expanded }) => {
  const dispatch = useDispatch();
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [hasPermissions, setHasPermissions] = useState(false);
  const user = useSelector((state) => state.user);

  const [firmwareQuery, fetchFirmwareResults] = useFetchFirmwareQuery({
    key: 'sidebar',
    autoRun: true,
    enableRefetchInterval: false,
  });
  const fetchMaintenanceQuery = useFetchScheduledMaintenanceEventsQuery({
    key: 'sidebar',
    autoRun: true,
  });

  useEffect(() => {
    let allowed = checkForUserPermission(user, permissionData.editfleet);
    setHasPermissions(allowed);
  }, []);

  const getAlertsFromMaintenanceData = (maintenanceItems) => {
    return maintenanceItems.map((item) => ({
      id: `${item.vehicleId}-${item.eventId}`,
      message: `${item.maintenanceType.label}`,
      subtitle: `${item.vehicleLabel}`,
      link: `/maintenance/${item.vehicleId}`,
      isMaintenance: true,
      icon:
        item.maintenanceType.value === maintenanceTypeDropdownData.DIAGNOSTIC
          ? 'check-engine'
          : 'maintenance',
    }));
  };

  useEffect(() => {
    if (!firmwareQuery.data) return;
    const filteredResultsRaw = firmwareQuery.data.filter((u) => u.firmware_status !== 'UP_TO_DATE');

    let filteredFirmwareAlerts = filteredResultsRaw.map((data) => ({
      id: `${data.product_id}-${data.channel}-${data.version}`,
      message: `${data.product_name ? data.product_name : data.product_type} Update`,
      version: data.version,
      link: `/updates/${data.product_id}/${data.channel}/${data.version}`,
      firmware_status: data.firmware_status,
      vehicle_id: data.vehicle_id,
    }));
    filteredFirmwareAlerts = filteredFirmwareAlerts.sort((a1, a2) =>
      sortAscendingAlpha(a1.message, a2.message),
    );

    let maintenanceAlerts = fetchMaintenanceQuery.data
      ? fetchMaintenanceQuery.data?.map((item) => mapMaintenanceDataToFrontend(item))
      : [];

    maintenanceAlerts = getAlertsFromMaintenanceData(
      maintenanceAlerts.filter(
        (item) =>
          item.status === maintenanceStatusConstants.DUE_FOR_WORK ||
          item.status === maintenanceStatusConstants.DUE_IN_SOON ||
          item.status === maintenanceStatusConstants.CHECK_ENGINE,
      ),
    );

    setFilteredAlerts([...filteredFirmwareAlerts, ...maintenanceAlerts]);
    // set alert count
    dispatch(setFirmwareAlertCount(filteredFirmwareAlerts.length + maintenanceAlerts.length));
  }, [firmwareQuery.data, fetchMaintenanceQuery.data, filteredOrganizationId]);

  useEffect(() => {
    if (hasPermissions && checkLogin()) {
      fetchMaintenanceQuery.refetch();
    }
  }, [filteredOrganizationId]);

  const hasAlerts = filteredAlerts?.length > 0;

  return (
    filteredAlerts && (
      <Wrapper style={{ zIndex: '1000' }} expanded={expanded}>
        <Head>
          <Title>New Alerts</Title>
        </Head>
        <Scrollbars
          style={{
            width: '100%',
            height: '100%',
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          <Body centerContent={!hasAlerts}>
            {hasAlerts ? (
              <List>
                {filteredAlerts.map((alert, idx) => (
                  <AlertLink
                    key={idx}
                    className="link"
                    to={alert.link || '/'}
                    onClick={() => {
                      dispatch(toggleSidebarAlerts());
                    }}
                  >
                    {alert.isMaintenance ? (
                      <MaintenanceItem>
                        <IconWrapper>
                          <IconSvgComponent svgFileName={alert.icon} />
                        </IconWrapper>
                        <div>
                          <div>{alert.message}</div>
                          <DetailDiv>{alert.subtitle}</DetailDiv>
                        </div>
                      </MaintenanceItem>
                    ) : (
                      <ListItem key={alert.id + uuid()}>
                        <AlertTitleWrapper title={alert.message}>{alert.message}</AlertTitleWrapper>
                        <DetailDiv>Update Now</DetailDiv>
                      </ListItem>
                    )}
                  </AlertLink>
                ))}
              </List>
            ) : (
              <MessageWrapper>
                <IconSvgComponent svgFileName="bell-large" alt="Alert" />

                <Title altstyle="true">New Alerts</Title>
              </MessageWrapper>
            )}
          </Body>
        </Scrollbars>
      </Wrapper>
    )
  );
};

export default SidebarAlertsNotifier;
