import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { undecorateVehicleObject } from 'legacy/core/api/vehicles';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import VehicleAssociatedGroups from 'legacy/features/vehicles/components/controls/associatedGroups/VehicleAssociatedGroups';
import VehicleDeviceIdField from 'legacy/features/vehicles/components/forms/custom/VehicleDeviceIdField';
import VehicleFormVinField from 'legacy/features/vehicles/components/forms/custom/VehicleFormVinField';
import SubscriptionTypeDropdown from 'legacy/features/vehicles/components/forms/select/SubscriptionTypeDropdown';
import VehicleLicenseStateSelect from 'legacy/features/vehicles/components/forms/select/VehicleLicenseStateSelect';
import VehicleTypeSelect from 'legacy/features/vehicles/components/forms/select/VehicleTypeSelect';
import VehicleAliasField from 'legacy/features/vehicles/components/forms/textField/VehicleAliasField';
import VehicleLicensePlateField from 'legacy/features/vehicles/components/forms/textField/VehicleLicensePlateField';
import VehicleMakeField from 'legacy/features/vehicles/components/forms/textField/VehicleMakeField';
import VehicleModelField from 'legacy/features/vehicles/components/forms/textField/VehicleModelField';
import VehicleNameField from 'legacy/features/vehicles/components/forms/textField/VehicleNameField';
import VehicleNotes from 'legacy/features/vehicles/components/forms/textField/VehicleNotes';
import VehicleYearField from 'legacy/features/vehicles/components/forms/textField/VehicleYearField';
import { vehicleCreateSuccess } from 'legacy/features/vehicles/helpers/notifications';
import useCreateVehicleMutation from 'legacy/features/vehicles/hooks/useCreateVehicleMutation';
import { validateVehicle } from 'legacy/features/vehicles/validators/validateVehicle';
import Button from 'legacy/shared/v1/styles/components/Button';
import FormWrapper, {
  FieldError,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormHead,
  FormIcon,
  FormLegend,
  FormMessage,
  FormTitle,
} from 'legacy/shared/v1/styles/components/Form';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const VehicleCreateFormContainer = ({ handleRedirect, vehicle, mappedVsgs }) => {
  const [associatedGroups, setAssociatedGroups] = useState([]);

  const [deviceIdDirty, setDeviceIsDirty] = useState(false);
  const [haasDirty, setHaasDirty] = useState(false);
  const [deviceContainerDirty, setDeviceContainerDirty] = useState(false);
  const [associatedGroupsDirty, setAssociatedGroupsDirty] = useState(false);
  const [addonsDirty, setAddonsDirty] = useState(false);
  const [resetPreferencesModalActive, setResetPreferencesModalActive] = useState(false);
  const [resetVsgConfirmed, setResetVsgConfirmed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [addonValidationErrors, setAddonValidationErrors] = useState(false);

  const [prefillMake, setPrefillMake] = useState(null);
  const [prefillModel, setPrefillModel] = useState(null);
  const [prefillYear, setPrefillYear] = useState(null);

  const [nullVsgModalConfirmed, setNullVsgModalConfirmed] = useState(false);
  const [nullVsgModalActive, setNullVsgModalActive] = useState(false);

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const [duid, setDuid] = useState(null);
  const [validDuids, setValidDuids] = useState(mappedVsgs?.map((vsg) => vsg.vsgDuid));
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);
  useEffect(() => {
    setValidDuids(mappedVsgs?.map((vsg) => vsg.vsgDuid));
    let selectedVsg = mappedVsgs.find(
      (vsg) => vsg.vsgDuid === duid || vsg.vsgDuid === duid?.replace(/-/g, ''),
    );

    if (selectedVsg?.subscriptionType !== 'UNALLOCATED')
      setSelectedSubscriptionType(selectedVsg?.subscriptionType);
    else setSelectedSubscriptionType(null);
  }, [duid]);

  const createVehicleMutation = useCreateVehicleMutation();

  const confirmHandler = async (values, formikActions) => {
    values.duid = values.duid?.replace(/-/g, '');
    values.vin = values.vin.toUpperCase();

    if (!values?.duid && !nullVsgModalConfirmed) {
      setNullVsgModalActive(true);
    } else {
      console.log('VALUES', values);
      createVehicleMutation.mutate(
        {
          ...undecorateVehicleObject({
            vehicle: {
              ...values,
              organizationId: filteredOrganizationId,
              groups: associatedGroups,
            },
          }),
        },
        {
          onSuccess: () => {
            dispatch(vehicleCreateSuccess({ vehicleName: values.label }));
            handleRedirect();
            formikActions.setSubmitting(false);
          },
          onError: (err) => {
            logWcpError(err);
            setServerError(err);
            formikActions.setSubmitting(false);
          },
        },
      );
    }
  };

  const dispatch = useDispatch();

  console.log('VehicleCreateFormContainer', mappedVsgs);

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          label: '',
          vin: '',
          duid: '',
          licensePlate: '',
          licenseState: '',
          type: '',
          model: '',
          make: '',
          year: '',
          notes: '',
          alias: '',
          device: '',
        }}
        validate={(values) => validateVehicle({ values, validDuids })}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.submitForm}>
              <FormHead>
                <FormIcon>
                  <IconSvgComponent svgFileName={'plus'} />
                </FormIcon>
                <FormTitle>Vehicle Details</FormTitle>
              </FormHead>

              <FormBody>
                <FormGridRow>
                  <FormCol>
                    <VehicleNameField formikProps={formikProps} />
                  </FormCol>
                  <FormCol>
                    <VehicleAliasField formikProps={formikProps} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleDeviceIdField
                      setDuid={setDuid}
                      duid={duid}
                      formikProps={formikProps}
                      unassociatedVsgs={mappedVsgs.filter((vsg) => !vsg.vehicleId)}
                      resetPreferencesModalActive={resetPreferencesModalActive}
                      handleDeviceIdDirty={setDeviceIsDirty}
                      nullVsgModalActive={nullVsgModalActive}
                      handleConfirmVsgResetPrefencesAccepted={(isAccepted) => {
                        // close modal
                        setResetPreferencesModalActive(false);

                        // submit if accepted
                        if (isAccepted) {
                          setResetVsgConfirmed(true);
                          formikProps.submitForm();
                        }
                      }}
                      handleConfirmVerifyVsgIdAccepted={(isAccepted) => {
                        // close modal
                        setNullVsgModalActive(false);

                        // submit if accepted
                        if (isAccepted) {
                          setNullVsgModalConfirmed(true);
                          formikProps.submitForm();
                        }
                      }}
                    />
                    <FieldError snugSpacing>{formikProps.errors.vsgDuid}</FieldError>
                  </FormCol>
                  <FormCol>
                    <SubscriptionTypeDropdown
                      formikProps={formikProps}
                      disabled={
                        mappedVsgs.find((vsg) => vsg.vsgDuid === duid)?.subscriptionType !==
                        'UNALLOCATED'
                      }
                      subscriptionType={selectedSubscriptionType}
                      setSelectedSubscriptionType={setSelectedSubscriptionType}
                    />
                    <FieldError snugSpacing>
                      {formikProps.touched.subscriptionType && formikProps.errors.subscriptionType}
                    </FieldError>
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleFormVinField
                      formikProps={formikProps}
                      setVinPrefills={(make, model, year) => {
                        setPrefillMake(make);
                        setPrefillModel(model);
                        setPrefillYear(year);
                      }}
                    />
                  </FormCol>
                  <FormCol>
                    <VehicleLicensePlateField formikProps={formikProps} />
                    <VehicleLicenseStateSelect formikProps={formikProps} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleTypeSelect formikProps={formikProps} />
                  </FormCol>
                  <FormCol>
                    <VehicleMakeField formikProps={formikProps} prefillValue={prefillMake} />
                  </FormCol>
                </FormGridRow>
                <FormGridRow>
                  <FormCol>
                    <VehicleModelField formikProps={formikProps} prefillValue={prefillModel} />
                  </FormCol>
                  <FormCol>
                    <VehicleYearField formikProps={formikProps} prefillValue={prefillYear} />
                  </FormCol>
                </FormGridRow>
                <VehicleNotes formikProps={formikProps} />

                {/* Associated Groups */}
                <VehicleAssociatedGroups
                  vehicle={vehicle}
                  updateVehicleAssociatedGroups={(ag) => {
                    setAssociatedGroups(ag);
                  }}
                  handleIsDirty={(isDirty) => {
                    setAssociatedGroupsDirty(isDirty);
                  }}
                />
                <FormMessage message={serverError}>{serverError}</FormMessage>
              </FormBody>

              <FormFoot>
                <FormLegend>
                  <FormAsterisk>*</FormAsterisk> &mdash; required fields
                </FormLegend>
                <FormActions>
                  <Button type="button" onClick={handleRedirect} withRightSpacer mediumAlt default>
                    Cancel
                  </Button>
                  <ButtonWithLoader
                    isLoading={createVehicleMutation.isPending || formikProps.isSubmitting}
                    disabled={
                      (!formikProps.dirty &&
                        !haasDirty &&
                        !associatedGroupsDirty &&
                        !addonsDirty &&
                        !deviceContainerDirty) ||
                      addonValidationErrors
                    }
                    confirmText={'Create Vehicle'}
                    loadingStyleProp={'submittingWithSpinnerModal'}
                    notLoadingStyleProp={'mediumAlt'}
                    clickHandler={formikProps.submitForm}
                  />
                </FormActions>
              </FormFoot>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};
export default VehicleCreateFormContainer;
