import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import {
  addHoursToDateTime,
  getDaysSince,
  getHoursSince,
  getMonthsSince,
  getNow,
  getShortDateInIsoFormat,
} from 'legacy/shared/v1/utilities/time';

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^\+{1}[0-9]{11,13}$/;
const phoneExtensionRegex = /^\d+$/;
export const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;
const deviceIdRegex =
  /^[0-9A-Fa-f]{8}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{12}$/;
const usernameRegex = /((?!.*?_{2})^[a-z][a-z0-9_]{0,126}[a-z0-9]{1}$)|^[a-z]$/;
const vinRegex =
  /^(?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{5})(?<check>[\dX])(?<vis>(?<year>[A-HJ-NPR-Z\d])(?<plant>[A-HJ-NPR-Z\d])(?<seq>[A-HJ-NPR-Z\d]{6}))$/;
export const positiveIntegerRegex = /^[1-9]\d*$/;
export const alphanumericExtendedRegex = /^[a-zA-Z0-9 .,'_()-]*$/;
export const asciiRegex = /^[\x00-\x7F]+$/;
export const numericRegex = /^(0|[1-9][0-9]*)$/;
export const wimAccountIdRegex = /^[A-Z0-9]{6}$/;
const appliedInformationBusinessIdRegex = /^[0-9]{8,8}$/;
const phoneRegexError = 'Please enter a phone number in the format +1 (234) 567-8901';
const phoneExtensionRegexError = 'Please enter an extension containing only numbers';
const emailRegexError = 'Please enter an email address in the format address@domain.com';
const usernameRegexError =
  'Please enter an alphanumeric username without capital letters that is less than 128 characters, and that does not start with a number';
export const dateRegexError = (dateFieldLabel) =>
  `Please enter a ${dateFieldLabel} in the format MM/DD/YYYY`;
const passwordRegexError =
  'Enter a password with at least 8 characters, uppercase, lowercase, and numeric characters';
const passwordMatchError = 'Please enter matching passwords';
const deviceIdRegexError = 'Please enter a valid 32 character VSG ID';
const vinRegexError = 'Please enter a valid VIN (note that O,I, and Q are not valid characters)';
export const alphanumericExtendedRegexError = 'Please enter only alphanumeric characters';
export const asciiRegexError = 'Please enter only ASCII characters';
export const numericRegexError = 'Please enter numbers only';
const wimAccountIdRegexError = 'Please enter a valid six digit account ID';
const appliedInformationRegexError = 'Please enter an eight digit numeric value';
export const positiveIntegerRegexError = 'Please enter a positive whole number';
import {
  stripCommas,
  stripTrailingPercentage,
} from 'legacy/features/maintenance/utilities/formatting';
import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';

export const positiveIntegerOneHundredOrLess = (nextValidator) => (value) => {
  const valueAsNumber = Number(stripTrailingPercentage(value));
  if (!Number.isInteger(valueAsNumber) || valueAsNumber > 99 || valueAsNumber < 1) {
    return 'Please enter a positive whole number less than 100';
  }
  if (typeof nextValidator === 'function') {
    return nextValidator(value);
  }
};

const vehicleYearValidator = (nextValidator) => (value) => {
  const valueAsNumber = Number(value);
  const yearLimit = new Date().getFullYear() + 2;
  if (!Number.isInteger(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber < 1950 || valueAsNumber > yearLimit) {
    return 'Please enter a valid year after 1950';
  }

  if (typeof nextValidator === 'function') {
    return nextValidator(value);
  }
};

const analyticsDateValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const difference = getDaysSince({
      fromDateTime: getShortDateInIsoFormat(value),
      toDateTime: getNow(),
    });
    if (difference < 0) {
      return 'Please enter a date today or earlier';
    }
    if (difference > 1096) {
      return 'Please enter a date less than 3 years ago';
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
  };

const otaLogsDateValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const difference = getDaysSince({
      fromDateTime: getShortDateInIsoFormat(value),
      toDateTime: getNow(),
    });
    if (difference < 0) {
      return 'Please enter a date today or earlier';
    }
    if (difference > 366) {
      return 'Please enter a date less than a year ago';
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
  };
export const endDateBeforeStartDateValidator = (startDate, endDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat(startDate),
      toDateTime: getShortDateInIsoFormat(endDate),
    }) < 0
  ) {
    return 'Please choose a date that is after the start date';
  }
};

export const analyticsLaunchDateValidator = (startDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('07/20/2021'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 07/20/21 or later';
  }
};

const otaLogsLaunchDateValidator = (startDate) => {
  if (
    //change this closer to release
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('12/05/2022'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 12/05/22 or later';
  }
};

export const isEmptyOrWhitespaceOnly = (value) => {
  //some values come back as ints and we can't use the trim method on non-strings
  let stringValue = value ? value.toString() : '';
  if (stringValue.trim() === '') {
    return 'This field is required';
  }
};

export const requiredValidator =
  (nextValidator) =>
  (value, ...rest) => {
    const requiredMessage = isEmptyOrWhitespaceOnly(value);
    if (requiredMessage) {
      return requiredMessage;
    }
    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }

    return null;
  };
const geofenceLogsLaunchDateValidator = (startDate) => {
  if (
    getDaysSince({
      fromDateTime: getShortDateInIsoFormat('05/23/2022'),
      toDateTime: getShortDateInIsoFormat(startDate),
    }) < 0
  ) {
    return 'Please choose a date that is 05/23/22 or later';
  }
};
export const characterLimitValidator =
  (nextValidator) =>
  (value, characterLimit, ...rest) => {
    if (value?.length > characterLimit) {
      return `Please enter ${characterLimit} characters or less`;
    }

    if (typeof nextValidator === 'function') {
      return nextValidator(...[value, ...rest]);
    }
    return null;
  };

export const regexValidator = (regex, errorMessage) => (value) => {
  if (regex instanceof RegExp) {
    if (value && !regex.test(String(value))) {
      return errorMessage;
    }
  }
};

const matchValidator = (errorMessage) => (matchString, value) => {
  if (value !== matchString) {
    return errorMessage;
  }
};

const userValidators = {
  email: requiredValidator(characterLimitValidator(regexValidator(emailRegex, emailRegexError))),
  username: requiredValidator(regexValidator(usernameRegex, usernameRegexError)),
  givenName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  familyName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  phoneNumber: regexValidator(phoneRegex, phoneRegexError),
  phoneExtension: regexValidator(phoneExtensionRegex, phoneExtensionRegexError),
  userTitle: characterLimitValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  userRole: requiredValidator(characterLimitValidator()),
};

const organizationValidators = {
  organizationName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  contactName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  contactPhone: requiredValidator(regexValidator(phoneRegex, phoneRegexError)),
  contactPhoneExtension: regexValidator(phoneExtensionRegex, phoneExtensionRegexError),
  contactEmail: requiredValidator(
    characterLimitValidator(regexValidator(emailRegex, emailRegexError)),
  ),
  vehicleLimit: requiredValidator(regexValidator(positiveIntegerRegex, positiveIntegerRegexError)),
  startDate: requiredValidator(
    regexValidator(dateRegex, dateRegexError('subscription start date')),
  ),
  endDate: requiredValidator(regexValidator(dateRegex, dateRegexError('subscription end date'))),
  endDateBeforeStartDate: endDateBeforeStartDateValidator,
  address1: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  address2: characterLimitValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  city: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  state: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  wimAccountId: requiredValidator(
    characterLimitValidator(regexValidator(wimAccountIdRegex, wimAccountIdRegexError)),
  ),
  appliedInformationBusinessId: requiredValidator(
    characterLimitValidator(
      regexValidator(appliedInformationBusinessIdRegex, appliedInformationRegexError),
    ),
  ),
  customerType: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  timeZone: requiredValidator(),
};

const dateRangeValidators = {
  startDate: requiredValidator(
    analyticsDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  endDate: requiredValidator(
    analyticsDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
  endDateBeforeStartDate: endDateBeforeStartDateValidator,
  startDateAfterAnalyticsLaunch: analyticsLaunchDateValidator,
  startDateAfterOtaLogsLaunch: otaLogsLaunchDateValidator,
  startDateAfterGeofenceLaunch: geofenceLogsLaunchDateValidator,
  otaLogsStartDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  otaLogsEndDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
};

const configurationValidators = {
  configName: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  description: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  archived: requiredValidator(),
};

const groupValidators = {
  groupName: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
};

const selectedConfigurationValidators = {
  selectedConfiguration: requiredValidator(),
};

const vehicleValidators = {
  label: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  vin: requiredValidator(regexValidator(vinRegex, vinRegexError)),
  type: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  make: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  model: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licensePlate: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licenseState: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  duid: requiredValidator(regexValidator(deviceIdRegex, deviceIdRegexError)),
  year: requiredValidator(vehicleYearValidator()),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  alias: characterLimitValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
};

const maintenanceRemindersValidators = {
  reminderMileage: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderEngineHours: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderDays: regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  reminderOilLife: requiredValidator(positiveIntegerOneHundredOrLess()),
};

const markMaintenanceCompletedValidators = {
  completedDate: requiredValidator(
    otaLogsDateValidator(regexValidator(dateRegex, dateRegexError('completed date'))),
  ),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  nextIntervalValue: requiredValidator(
    regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  ),
  nextOilLifeInterval: requiredValidator(positiveIntegerOneHundredOrLess()),
  nextDueDateRequired: requiredValidator(),
};

const helpValidators = {
  type: requiredValidator(),
  description: requiredValidator(
    characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  ),
};

const passwordValidators = {
  oldPassword: requiredValidator(),
  password: requiredValidator(regexValidator(passwordRegex, passwordRegexError)),
  confirmPassword: requiredValidator(matchValidator(passwordMatchError)),
  username: requiredValidator(),
  resetCode: requiredValidator(),
};

export const validateVehicle = (values) => {
  const labelErrors = vehicleValidators.label(values.label, 36);
  const vinErrors = vehicleValidators.vin(values.vin.toUpperCase());
  const typeErrors = vehicleValidators.type(values.type);
  const makeErrors = vehicleValidators.make(values.make, 128);
  const modelErrors = vehicleValidators.model(values.model, 128);
  const licensePlateErrors = vehicleValidators.licensePlate(values.licensePlate, 12);
  const licenseStateErrors = vehicleValidators.licenseState(values.licenseState);
  const deviceIdErrors = vehicleValidators.duid(values.duid);
  const yearErrors = vehicleValidators.year(values.year);
  const notesErrors = vehicleValidators.notes(values.notes, 1000);
  const aliasErrors = vehicleValidators.alias(values.alias, 36);

  return {
    ...(labelErrors ? { label: labelErrors } : {}),
    ...(vinErrors ? { vin: vinErrors } : {}),
    ...(typeErrors ? { type: typeErrors } : {}),
    ...(makeErrors ? { make: makeErrors } : {}),
    ...(modelErrors ? { model: modelErrors } : {}),
    ...(licensePlateErrors ? { licensePlate: licensePlateErrors } : {}),
    ...(licenseStateErrors ? { licenseState: licenseStateErrors } : {}),
    ...(deviceIdErrors ? { duid: deviceIdErrors } : {}),
    ...(yearErrors ? { year: yearErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
    ...(aliasErrors ? { alias: aliasErrors } : {}),
  };
};

export const validateAliasModal = (values) => {
  const aliasErrors = vehicleValidators.alias(values.alias, 36);

  return {
    ...(aliasErrors ? { alias: aliasErrors } : {}),
  };
};

export const validateMaintenanceReminders = (values) => {
  const reminderMileageErrors = maintenanceRemindersValidators.reminderMileage(
    stripCommas(values.reminderMileage),
  );
  const reminderEngineHoursErrors = maintenanceRemindersValidators.reminderEngineHours(
    stripCommas(values.reminderEngineHours),
  );
  const reminderDaysErrors = maintenanceRemindersValidators.reminderDays(values.reminderDays);

  const reminderOilLifeErrors = maintenanceRemindersValidators.reminderOilLife(
    values.reminderOilLife,
  );

  return {
    ...(reminderMileageErrors ? { reminderMileage: reminderMileageErrors } : {}),
    ...(reminderEngineHoursErrors ? { reminderEngineHours: reminderEngineHoursErrors } : {}),
    ...(reminderDaysErrors ? { reminderDays: reminderDaysErrors } : {}),
    ...(reminderOilLifeErrors ? { reminderOilLife: reminderOilLifeErrors } : {}),
  };
};

export const findNextIntervalValidator = (intervalType, nextIntervalValue) => {
  if (intervalType === maintenanceIntervalConstants.DATE) {
    return;
  } else if (intervalType === maintenanceIntervalConstants.OIL_LIFE) {
    const error = markMaintenanceCompletedValidators.nextOilLifeInterval(nextIntervalValue);
    return error;
  } else {
    const error = markMaintenanceCompletedValidators.nextIntervalValue(
      stripCommas(nextIntervalValue),
    );
    return error;
  }
};

export const validateMarkMaintenanceCompleted = (values) => {
  const completedDateErrors = markMaintenanceCompletedValidators.completedDate(
    values.completedDate,
  );
  const notesErrors = markMaintenanceCompletedValidators.notes(values.notes, 1000);
  const nextIntervalValueErrors =
    values.status !== maintenanceStatusConstants.CHECK_ENGINE &&
    findNextIntervalValidator(values.nextIntervalType?.value, values.nextIntervalValue);

  const nextDueDateErrors =
    values.nextIntervalType?.value === maintenanceIntervalConstants.DATE &&
    values.status !== maintenanceStatusConstants.CHECK_ENGINE &&
    markMaintenanceCompletedValidators.nextDueDateRequired(values.nextDueDate);
  return {
    ...(completedDateErrors ? { completedDate: completedDateErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
    ...(nextIntervalValueErrors ? { nextIntervalValue: nextIntervalValueErrors } : {}),
    ...(nextDueDateErrors ? { nextDueDate: nextDueDateErrors } : {}),
  };
};

export const validateHelp = (values) => {
  const typeErrors = helpValidators.type(values.type);
  const descriptionErrors = helpValidators.description(values.description, 1000);
  return {
    ...(typeErrors ? { type: typeErrors } : {}),
    ...(descriptionErrors ? { description: descriptionErrors } : {}),
  };
};

export const validateUser = (values) => {
  const phoneValue = values.phoneNumber && `+${values.phoneNumber.replace(/\D/g, '')}`;
  const emailErrors = userValidators.email(values.email, 256);
  const usernameErrors = userValidators.username(values.username);
  const givenNameErrors = userValidators.givenName(values.givenName, 128);
  const familyNameErrors = userValidators.familyName(values.familyName, 128);
  const phoneNumberErrors = userValidators.phoneNumber(phoneValue);
  const phoneExtensionErrors = userValidators.phoneExtension(values.phoneExtension);
  const userTitleErrors = userValidators.userTitle(values.userTitle, 128);
  const userRoleErrors = userValidators.userRole(values.userRole, 128);
  return {
    ...(emailErrors ? { email: emailErrors } : {}),
    ...(usernameErrors ? { username: usernameErrors } : {}),
    ...(givenNameErrors ? { givenName: givenNameErrors } : {}),
    ...(familyNameErrors ? { familyName: familyNameErrors } : {}),
    ...(phoneNumberErrors ? { phoneNumber: phoneNumberErrors } : {}),
    ...(phoneExtensionErrors ? { phoneExtension: phoneExtensionErrors } : {}),
    ...(userTitleErrors ? { userTitle: userTitleErrors } : {}),
    ...(userRoleErrors ? { userRole: userRoleErrors } : {}),
  };
};

export const validateUserPassword = (values) => {
  const oldPasswordErrors = passwordValidators.oldPassword(values.oldPassword);
  const newPasswordErrors = passwordValidators.password(values.newPassword);
  const confirmNewPasswordErrors = passwordValidators.confirmPassword(
    values.newPassword,
    values.confirmNewPassword,
  );
  return {
    ...(oldPasswordErrors ? { oldPassword: oldPasswordErrors } : {}),
    ...(newPasswordErrors ? { newPassword: newPasswordErrors } : {}),
    ...(confirmNewPasswordErrors ? { confirmNewPassword: confirmNewPasswordErrors } : {}),
  };
};

export const validateConfiguration = (values) => {
  const configNameErrors = configurationValidators.configName(values.configName, 128);
  const descriptionErrors = configurationValidators.description(values.description, 256);
  const archivedErrors = configurationValidators.archived(values.archived);
  return {
    ...(configNameErrors ? { configName: configNameErrors } : {}),
    ...(descriptionErrors ? { description: descriptionErrors } : {}),
    ...(archivedErrors ? { archived: archivedErrors } : {}),
  };
};

export const validateGroup = (values) => {
  const groupNameErrors = groupValidators.groupName(values.groupName);
  return {
    ...(groupNameErrors ? { groupName: groupNameErrors } : {}),
  };
};

export const validateSelectedConfiguration = (values) => {
  const selectedConfigurationErrors = selectedConfigurationValidators.selectedConfiguration(
    values.selectedConfiguration,
  );
  return {
    ...(selectedConfigurationErrors ? { selectedConfiguration: selectedConfigurationErrors } : {}),
  };
};

export const validateOrganization = (values) => {
  const phoneValue = values.contactPhone && `+${values.contactPhone.replace(/\D/g, '')}`;
  const organizationNameErrors = organizationValidators.organizationName(values.organizationName);
  const contactNameErrors = organizationValidators.contactName(values.contactName, 128);
  const contactPhoneErrors = organizationValidators.contactPhone(phoneValue);
  const contactPhoneExtensionErrors = organizationValidators.contactPhoneExtension(
    values.contactPhoneExtension,
  );
  const contactEmailErrors = organizationValidators.contactEmail(values.contactEmail, 128);
  const vehicleLimitErrors = organizationValidators.vehicleLimit(values.vehicleLimit);
  const startDateErrors = organizationValidators.startDate(values.startDate);
  const endDateErrors = organizationValidators.endDate(values.endDate);
  const endDateBeforeStartDateError = organizationValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const address1Errors = organizationValidators.address1(values.address1, 128);
  const address2Errors = organizationValidators.address2(values.address2, 128);
  const cityErrors = organizationValidators.address1(values.city, 128);
  const stateErrors = organizationValidators.address1(values.state);
  const wimAccountIdErrors = organizationValidators.wimAccountId(values.wimAccountId, 6);
  const appliedInformationBusinessIdErrors =
    values.appliedInformationEnabled &&
    organizationValidators.appliedInformationBusinessId(values.appliedInformationBusinessId, 8);
  const customerTypeErrors = organizationValidators.customerType(values.customerType);
  const timeZoneErrors = organizationValidators.timeZone(values.timeZone);

  return {
    ...(organizationNameErrors ? { organizationName: organizationNameErrors } : {}),
    ...(contactNameErrors ? { contactName: contactNameErrors } : {}),
    ...(contactPhoneErrors ? { contactPhone: contactPhoneErrors } : {}),
    ...(contactPhoneExtensionErrors ? { contactPhoneExtension: contactPhoneExtensionErrors } : {}),
    ...(contactEmailErrors ? { contactEmail: contactEmailErrors } : {}),
    ...(vehicleLimitErrors ? { vehicleLimit: vehicleLimitErrors } : {}),
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(address1Errors ? { address1: address1Errors } : {}),
    ...(address2Errors ? { address2: address2Errors } : {}),
    ...(cityErrors ? { city: cityErrors } : {}),
    ...(stateErrors ? { state: stateErrors } : {}),
    ...(wimAccountIdErrors ? { wimAccountId: wimAccountIdErrors } : {}),
    ...(appliedInformationBusinessIdErrors
      ? { appliedInformationBusinessId: appliedInformationBusinessIdErrors }
      : {}),
    ...(customerTypeErrors ? { customerType: customerTypeErrors } : {}),
    ...(timeZoneErrors ? { timeZone: timeZoneErrors } : {}),
  };
};

export const validatePasswordResetForm = (values) => {
  const resetPasswordErrors = passwordValidators.password(values.resetPassword);
  const confirmPasswordErrors = passwordValidators.confirmPassword(
    values.resetPassword,
    values.confirmPassword,
  );
  return {
    ...(resetPasswordErrors ? { resetPassword: resetPasswordErrors } : {}),
    ...(confirmPasswordErrors ? { confirmPassword: confirmPasswordErrors } : {}),
  };
};

export const validateAnalyticsDateRange = (values) => {
  const startDateErrors = dateRangeValidators.startDate(values.startDate);
  const endDateErrors = dateRangeValidators.endDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterAnalyticsLaunchError = dateRangeValidators.startDateAfterAnalyticsLaunch(
    values.startDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterAnalyticsLaunchError
      ? { startDate: startDateAfterAnalyticsLaunchError }
      : {}),
  };
};

export const validateLogsRange = (values) => {
  const startDateErrors = dateRangeValidators.otaLogsStartDate(values.startDate);
  const endDateErrors = dateRangeValidators.otaLogsEndDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterOtaLogsLaunchError = dateRangeValidators.startDateAfterOtaLogsLaunch(
    values.startDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterOtaLogsLaunchError ? { startDate: startDateAfterOtaLogsLaunchError } : {}),
  };
};

export const validateGeofenceLogsRange = (values) => {
  const startDateErrors = dateRangeValidators.otaLogsStartDate(values.startDate);
  const endDateErrors = dateRangeValidators.otaLogsEndDate(values.endDate);
  const endDateBeforeStartDateError = dateRangeValidators.endDateBeforeStartDate(
    values.startDate,
    values.endDate,
  );
  const startDateAfterGeofenceLaunchError = dateRangeValidators.startDateAfterGeofenceLaunch(
    values.startDate,
  );
  return {
    ...(startDateErrors ? { startDate: startDateErrors } : {}),
    ...(endDateErrors ? { endDate: endDateErrors } : {}),
    ...(endDateBeforeStartDateError ? { endDate: endDateBeforeStartDateError } : {}),
    ...(startDateAfterGeofenceLaunchError ? { startDate: startDateAfterGeofenceLaunchError } : {}),
  };
};
