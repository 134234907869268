import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { devicesAndBillingSummaryTableColumns } from 'legacy/features/billing/ui/components/tables/DevicesAndBillingSummaryTableColumns';

const DevicesAndBillingSummaryTable = ({ handleUpdateListingCount }) => {
  const {
    state: { filteredDeviceSubscriptions },
  } = useDevicesAndBillingSummaryProviderContext();

  let filteredDeviceSubscriptionsTableData =
    filteredDeviceSubscriptions?.map((deviceSubscription) => {
      return {
        formattedDeviceId: deviceSubscription.decorated.formattedDeviceId,
        vehicleLabelDisplay: deviceSubscription.decorated.vehicleLabelDisplay,
        formattedShipDate: deviceSubscription.decorated.formattedShipDate,
        formattedStartDate: deviceSubscription.decorated.formattedStartDate,
        formattedEndDate: deviceSubscription.decorated.formattedEndDate,
        subscriptionType: deviceSubscription.subscriptionType,
        cellNetworkType: deviceSubscription.cellNetworkType,
        vsgDuid: deviceSubscription.vsgDuid,
        aboutToExpire: deviceSubscription.decorated.aboutToExpire,
        isExpired: deviceSubscription.decorated.isExpired,
        vehicleId: deviceSubscription.vehicleId,
        startDate: deviceSubscription.startDate,
        endDate: deviceSubscription.endDate,
        shipDate: deviceSubscription.factoryScanDate,
        monthsLeftInSubscription: deviceSubscription.decorated.monthsLeftInSubscription,
      };
    }) || [];

  return (
    <WcpTable
      id="devicesAndSubscriptionsSummaryTable"
      columns={devicesAndBillingSummaryTableColumns}
      tableSourceData={filteredDeviceSubscriptionsTableData}
      emptyText="No vehicles are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default DevicesAndBillingSummaryTable;
