import { getSubscriptionWarningIcon } from 'legacy/features/billing/services/utilities/getSubscriptionWarningIcon';
import { getPremiumTag } from 'legacy/features/billing/services/utilities/getPremiumTag';
import {
  ActionMenuBtnWrapper,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'legacy/shared/v1/styles/components/MobileCard';

const DevicesAndBillingSummaryCard = ({ deviceSubscription, actionMenuComponent }) => {
  return (
    <CardOuterWrapper>
      <CardContainer deviceSummary>
        <ActionMenuBtnWrapper>{actionMenuComponent(deviceSubscription)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardRow header>
            <CardName potentialLongValue vsgId>
              {deviceSubscription.decorated.formattedDeviceId}
            </CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow potentialLongValue>
          <CardLabel>Associated Vehicle: </CardLabel>
          <CardValue>{deviceSubscription.decorated.vehicleLabelDisplay}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Shipped On:</CardLabel>
          <CardValue>{deviceSubscription.decorated.formattedShipDate}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Start Date:</CardLabel>
          <CardValue>{deviceSubscription.decorated.formattedStartDate}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel
            bold={deviceSubscription.decorated.aboutToExpire}
            red={deviceSubscription.decorated.isExpired}
          >
            End Date:
          </CardLabel>
          <CardValue
            bold={deviceSubscription.decorated.aboutToExpire}
            red={deviceSubscription.decorated.isExpired}
          >
            {deviceSubscription.decorated.formattedEndDate}
          </CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel
            bold={deviceSubscription.decorated.aboutToExpire}
            red={deviceSubscription.decorated.isExpired}
          >
            Subscription:
          </CardLabel>
          <CardValue
            bold={deviceSubscription.decorated.aboutToExpire}
            red={deviceSubscription.decorated.isExpired}
          >
            {getSubscriptionWarningIcon(deviceSubscription)}
            {getPremiumTag(deviceSubscription.subscriptionType)}
          </CardValue>
        </CardRow>
        {}
        <CardRow potentialLongValue>
          <CardLabel>Cell Network:</CardLabel>
          <CardValue>{deviceSubscription.cellNetworkType}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default DevicesAndBillingSummaryCard;
