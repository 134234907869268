import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlStaging,
  baseApiUrlTest,
  baseApiUrlApp,
} from 'legacy/shared/v1/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  // This is here to make sure that npm start works.
  // Don't remove it please.
  //
  // default localhost to dev but this could be changed
  if (!host || host.includes('localhost:')) {
    return baseApiUrlDev;
  }

  if ('cloud.whelen.com' === host) {
    return baseApiUrlProd;
  }

  // Legacy / Masheen'd Environments...Kept for backwards compatibility
  // https://api.firstresponder.ai/dev
  // https://api.firstresponder.ai/staging
  // https://api.firstresponder.ai/prod
  // https://cloud.whelen.com/

  // The Now (Engineering Environments and the Future Environments.)
  // https://api.<stackname>.firstresponder.ai/
  // This gets a different file subbed out on deployments

  // Masheed'd comment of their previous understanding
  // this should return the url in the format https://{baseapiurl}/{stackname} (i.e. https://api.firstresponder.ai/NAME)
  // optionally you can comment out the following line in favor of the one after it in order to test on mobile via local server
  return baseApiUrlApp + getEnv();
  //return baseApiUrlDev;
})();

export default apiUrl;
