import { getSubscriptionWarningIcon } from 'legacy/features/billing/services/utilities/getSubscriptionWarningIcon';
import DevicesAndBillingActionMenu from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/DevicesAndBillingActionMenu';
import { DeviceColumn } from 'legacy/shared/v1/styles/components/DevicesAndBilling';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';
import { getPremiumTag } from 'legacy/features/billing/services/utilities/getPremiumTag';
import { getVehicleLink } from 'legacy/features/billing/services/utilities/getVehicleLink';

export const devicesAndBillingSummaryTableColumns = [
  {
    title: 'Device ID',
    displayProperty: 'formattedDeviceId',
    className: 'name',
    width: '30%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={'Device ID: ' + cellValue}
        >
          <TruncatedTextWithWrap>
            {getVehicleLink(rowData.vehicleId, cellJsx)}
          </TruncatedTextWithWrap>
        </DeviceColumn>
      );
    },
  },
  {
    title: 'Associated Vehicle',
    displayProperty: 'vehicleLabelDisplay',
    width: '20%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={'Associated Vehicle: ' + cellValue}
        >
          <TruncatedTextWithWrap>
            {getVehicleLink(rowData.vehicleId, cellJsx)}
          </TruncatedTextWithWrap>
        </DeviceColumn>
      );
    },
  },

  {
    title: 'Shipped On',
    displayProperty: 'formattedShipDate',
    sortProperty: 'shipDate',
    width: '10%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={`Shipped On: ${cellValue}`}
        >
          {cellJsx}
        </DeviceColumn>
      );
    },
  },

  {
    title: 'Start Date',
    displayProperty: 'formattedStartDate',
    sortProperty: 'startDate',
    width: '10%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={`Start Date: ${cellValue} `}
        >
          {cellJsx}
        </DeviceColumn>
      );
    },
  },

  {
    title: 'End Date',
    displayProperty: 'formattedEndDate',
    sortProperty: 'endDate',
    width: '10%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={`End Date: ${cellValue} `}
        >
          {cellJsx}
        </DeviceColumn>
      );
    },
  },

  {
    title: 'Subscription',
    displayProperty: 'subscriptionType',
    width: '13%',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={`Subscription: ${cellValue}`}
        >
          {getSubscriptionWarningIcon({ decorated: rowData })}
          {getPremiumTag(cellValue, cellJsx)}
        </DeviceColumn>
      );
    },
  },

  {
    title: 'Cell Network',
    displayProperty: 'cellNetworkType',
    render({ rowData, cellJsx, cellValue }) {
      return (
        <DeviceColumn
          dueForRenewal={rowData.aboutToExpire}
          highAlert={rowData.isExpired}
          title={`Cell Network: ${cellValue} `}
        >
          {cellJsx}
        </DeviceColumn>
      );
    },
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    key: 'a',
    render({ rowData }) {
      return (
        <DevicesAndBillingActionMenu
          vsgDuid={rowData.vsgDuid}
          id={'devicesAndSubscriptionsActionMenu' + rowData.rowClassName}
        />
      );
    },
  },
];
