import { PremiumTagDiv } from 'legacy/shared/v1/styles/components/DevicesAndBilling';

export const getPremiumTag = (subscriptionType, cellJsx) => {
  //card will not send in cellJsx, so just pass back the sub type text
  const textToDisplay = cellJsx ? cellJsx : subscriptionType;
  {
    return subscriptionType === 'Premium' ? (
      <PremiumTagDiv>{subscriptionType}</PremiumTagDiv>
    ) : (
      textToDisplay
    );
  }
};
