import ProtectedElement from 'legacy/core/components/authorization/ProtectedElement';
import { checkForUserPermission } from 'legacy/core/redux/user/selectors';
import AssignAddonsMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/AssignAddonsMenuItem';
import AssignConfigurationMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/AssignConfigurationMenuItem';
import DeleteGroupMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/DeleteGroupMenuItem';
import DuplicateGroupMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/DuplicateGroupMenuItem';
import EditGroupMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/EditGroupMenuItem';
import ManageHaasIntegrationMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/ManageHaasIntegrationMenuItem';
import RenameGroupMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/RenameGroupMenuItem';
import ResetAllGroupVehiclesMenuItem from 'legacy/features/vehicleGroups/ui/menus/menuItems/ResetAllGroupVehiclesMenuItem';
import AssignConfigurationsModal from 'legacy/features/vehicleGroups/ui/modals/AssignConfigurationsModal';
import DeleteGroupModal from 'legacy/features/vehicleGroups/ui/modals/DeleteGroupModal';
import DuplicateGroupModal from 'legacy/features/vehicleGroups/ui/modals/DuplicateGroupModal';
import ManageHaasIntegrationModal from 'legacy/features/vehicleGroups/ui/modals/ManageHaasIntegrationModal';
import RenameGroupModal from 'legacy/features/vehicleGroups/ui/modals/RenameGroupModal';
import ResetGroupVehiclesModal from 'legacy/features/vehicleGroups/ui/modals/ResetGroupVehiclesModal';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  allAdminUserRoles,
  FIELD_SOLUTION_ENGINEER,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  permissionData,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/v1/constants/users';
import ActionMenu from 'legacy/shared/v1/ui/table/ActionMenu';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';

const GroupListActionMenu = ({ group }) => {
  const permissions = useSelector((state) => ({
    editAccess: checkForUserPermission(state.user, permissionData.editfleet),
    viewConfigAccess: checkForUserPermission(state.user, permissionData.viewotaconfig),
  }));

  const [duplicateGroupModalData, setDuplicateModalData] = useState({ show: false });
  const [assignConfigurationsModalData, setAssignConfigurationsModalData] = useState({
    show: false,
  });
  const [manageHaasConfigurationsModalData, setManageHaasConfigurationsModalData] = useState({
    show: false,
  });
  const [renameGroupModalData, setRenameGroupModalData] = useState({ show: false });
  const [deleteGroupModalData, setDeleteGroupModalData] = useState({ show: false });
  const [resetGroupVehiclesModalData, setResetGroupVehiclesModalData] = useState({ show: false });

  useEffect(() => {
    setDuplicateModalData({
      show: false,
      groupName: group.groupName,
      vehicleIds: group.vehicleId,
    });

    setAssignConfigurationsModalData({
      show: false,
      groupId: group.groupId,
      vehicleIds: group.vehicleId,
    });

    setManageHaasConfigurationsModalData({
      show: false,
      groupId: group.groupId,
      vehicleIds: group.vehicleId,
    });

    setRenameGroupModalData({
      show: false,
      groupId: group.groupId,
      groupName: group.groupName,
    });

    setDeleteGroupModalData({
      show: false,
      groupId: group.groupId,
      groupName: group.groupName,
    });

    setResetGroupVehiclesModalData({
      show: false,
      vehicleIds: group.vehicleId,
    });
  }, [group]);

  const organization = useSelector((state) =>
    state.organizations.organizations.find(
      (o) => o.organizationId === state.user.filteredOrganizationId,
    ),
  );

  return (
    permissions.editAccess && (
      <>
        <ActionMenu>
          <EditGroupMenuItem groupName={group.groupName} />
          <RenameGroupMenuItem
            onMenuItemClick={() =>
              setRenameGroupModalData((prevState) => ({ ...prevState, show: true }))
            }
          />
          {permissions.viewConfigAccess && group.vehicleCount > 0 && (
            <AssignConfigurationMenuItem
              onMenuItemClick={() =>
                setAssignConfigurationsModalData((prevState) => ({ ...prevState, show: true }))
              }
            />
          )}
          {organization.haasEnabled && organization.haasSetup && (
            <ManageHaasIntegrationMenuItem
              onMenuItemClick={() =>
                setManageHaasConfigurationsModalData((prevState) => ({ ...prevState, show: true }))
              }
            />
          )}

          {group.vehicleCount > 0 && <AssignAddonsMenuItem groupId={group.groupId} />}

          <DuplicateGroupMenuItem
            onMenuItemClick={() =>
              setDuplicateModalData((prevState) => ({ ...prevState, show: true }))
            }
          />

          {group.vehicleCount > 0 && (
            <ProtectedElement
              allowedRoles={[
                userRoles[FIELD_SOLUTION_ENGINEER].value,
                userRoles[SYSTEM_ADMIN].value,
                userRoles[FLEET_ANALYST].value,
                userRoles[ORGANIZATION_ADMIN].value,
              ]}
            >
              <ResetAllGroupVehiclesMenuItem
                onMenuItemClick={() =>
                  setResetGroupVehiclesModalData((prevState) => ({ ...prevState, show: true }))
                }
              />
            </ProtectedElement>
          )}
          <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
            <DeleteGroupMenuItem
              onMenuItemClick={() =>
                setDeleteGroupModalData((prevState) => ({ ...prevState, show: true }))
              }
            />
          </PermissionProtectedElement>
        </ActionMenu>
        {/* Modals */}
        <>
          <RenameGroupModal
            modalData={renameGroupModalData}
            handleCloseModal={() =>
              setRenameGroupModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
          <DuplicateGroupModal
            modalData={duplicateGroupModalData}
            handleCloseModal={() =>
              setDuplicateModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
          <DeleteGroupModal
            modalData={deleteGroupModalData}
            handleCloseModal={() =>
              setDeleteGroupModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
          <ResetGroupVehiclesModal
            modalData={resetGroupVehiclesModalData}
            handleCloseModal={() =>
              setResetGroupVehiclesModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
          <AssignConfigurationsModal
            modalData={assignConfigurationsModalData}
            group={group}
            handleCloseModal={() =>
              setAssignConfigurationsModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
          <ManageHaasIntegrationModal
            group={group}
            modalData={manageHaasConfigurationsModalData}
            handleCloseModal={() =>
              setManageHaasConfigurationsModalData((prevState) => ({ ...prevState, show: false }))
            }
          />
        </>
      </>
    )
  );
};

export default GroupListActionMenu;
