import { Link } from 'react-router-dom';

export const getVehicleLink = (vehicleId, cellJsx) => {
  return vehicleId ? (
    <Link
      id="vsgDuidlinkToVehicleEditPage"
      to={{
        pathname: `/vehicle/${encodeURIComponent(vehicleId)}`,
      }}
      style={{ textDecoration: 'none' }}
    >
      {cellJsx}
    </Link>
  ) : (
    cellJsx
  );
};
