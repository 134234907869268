import { useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSubscriptionDevices } from 'legacy/features/billing/api/get/getSubscriptionDevices';
import { cellNetworkTypeConstants } from 'legacy/features/billing/data/enums/cellNetworkTypeConstants';
import { subscriptionTypeConstants } from 'legacy/features/billing/data/enums/subscriptionTypeConstants';
import {
  determineHighAlertDisplay,
  determineMonthsLeftInSubscription,
  determineRenewalDisplay,
} from 'legacy/features/billing/services/utilities/subscriptionUtilities';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { formatISOTimestampToShortDate } from 'legacy/shared/v1/utilities/time';
import { getFormattedDuid } from 'legacy/shared/v2/utilities/getFormattedDuid';
import { getSubscriptionOrders } from 'legacy/features/billing/api/get/getSubscriptionOrders';

// create context
export const DevicesAndBillingContext = createContext(null);

// TODO refactor to proper provider
const useQuickAndDirtySubscriptionQuery = ({
  showNeedsAttentionDevicesFilter,
  onlyShowUnallocatedDevicesFilter,
}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const subscriptionDevicesQuery = useQuery({
    queryKey: ['getSubscriptionDevicesApi'],
    queryFn: () => getSubscriptionDevices(filteredOrganizationId),

    select: (data) => {
      let deviceSubscriptions = data && Array.isArray(data) ? data : null;

      let mappedDeviceSubscriptions = deviceSubscriptions.map((ds) => {
        return Object.entries(ds).reduce((acc, [key, value]) => {
          let newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
          acc[newKey] = value;
          return acc;
        }, {});
      });

      let decoratedDeviceSubscriptions = mappedDeviceSubscriptions?.map((ds, i) => {
        switch (ds.subscriptionType) {
          case subscriptionTypeConstants.PROMO: {
            ds.subscriptionType = 'Promo';
            break;
          }
          case subscriptionTypeConstants.BASIC: {
            ds.subscriptionType = 'Standard';
            break;
          }
          case subscriptionTypeConstants.PREMIUM: {
            ds.subscriptionType = 'Premium';
            break;
          }
          default:
            ds.subscriptionType = 'N/A';
        }

        switch (ds.cellNetworkType) {
          case cellNetworkTypeConstants.VERIZON_FRONTLINE: {
            ds.cellNetworkType = 'Verizon Frontline';
            break;
          }
          case cellNetworkTypeConstants.VERIZON_STANDARD: {
            ds.cellNetworkType = 'Verizon';
            break;
          }
          default:
            ds.cellNetworkType = 'N/A';
        }
        let monthsLeft = determineMonthsLeftInSubscription(ds.endDate);

        // grace is treated the same as expired on a device level
        let isExpired = ds.subscriptionStatus === 'EXPIRED' || ds.subscriptionStatus === 'GRACE';

        ds.decorated = {
          vehicleLabelDisplay: ds.vehicleLabel ? ds.vehicleLabel : 'N/A',
          isExpired,
          // months left plus one beacuse the warning message will say
          // "device will expire in x months of less, so when its 0 it should say 1 month of less"
          monthsLeftInSubscription: Number(monthsLeft + 1),
          aboutToExpire: monthsLeft < 4 && !isExpired,
          highAlert: isExpired,

          formattedStartDate: formatISOTimestampToShortDate(ds.startDate),
          formattedEndDate: formatISOTimestampToShortDate(ds.endDate),
          formattedDeviceId: getFormattedDuid(ds.vsgDuid),
          formattedShipDate: ds.factoryScanDate
            ? formatISOTimestampToShortDate(ds.factoryScanDate)
            : 'N/A',
        };
        return ds;
      });

      return decoratedDeviceSubscriptions;
    },
  });

  const subscriptionSummaryQuery = useQuery({
    queryKey: ['getSubscriptionOrdersSummaryApi'],
    queryFn: () => getSubscriptionOrders(filteredOrganizationId),

    select: (data) => {
      let deviceSubscriptionOrdersSummary = data ? data : null;

      let mappedDeviceSubscriptionOrdersSummary = Object.entries(
        deviceSubscriptionOrdersSummary,
      ).reduce((acc, [key, value]) => {
        let newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        acc[newKey] = value;
        return acc;
      }, {});
      mappedDeviceSubscriptionOrdersSummary.formattedNextExpirationDate =
        formatISOTimestampToShortDate(mappedDeviceSubscriptionOrdersSummary.nextExpirationDate);

      return mappedDeviceSubscriptionOrdersSummary;
    },
  });

  return {
    isLoading:
      subscriptionDevicesQuery.isFetching ||
      !subscriptionDevicesQuery.data ||
      subscriptionSummaryQuery.isFetching ||
      !subscriptionSummaryQuery.data,
    deviceSubscriptions: subscriptionDevicesQuery.data,
    filteredDeviceSubscriptions: (() => {
      if (showNeedsAttentionDevicesFilter) {
        return subscriptionDevicesQuery.data?.filter(
          (ds) => ds.decorated.aboutToExpire || ds.decorated.isExpired,
        );
      } else if (onlyShowUnallocatedDevicesFilter) {
        return subscriptionDevicesQuery.data?.filter((ds) => ds.vehicleId === null);
      } else {
        return subscriptionDevicesQuery.data;
      }
    })(),
    organizationSubscriptionSummary: (() => {
      return subscriptionSummaryQuery.data;
    })(),
    subscriptionExpirationSummary: (() => {
      // due for renewal = between  (not included) and 3 included
      // needs attention = due for renewal + expired

      if (!subscriptionDevicesQuery.data) return;

      const aboutToExpire = subscriptionDevicesQuery.data.filter((d) => d.decorated.aboutToExpire);
      const haveExpired = subscriptionDevicesQuery.data.filter((d) => d.decorated.isExpired);
      const needAttention = [...aboutToExpire, ...haveExpired];

      return {
        aboutToExpire,
        haveExpired,
        needAttention,
      };
    })(),
  };
};

const DevicesAndBillingSummaryProvider = ({ children }) => {
  const [showNeedsAttentionDevicesFilter, setShowNeedsAttentionDevicesFilter] = useState(false);
  const [onlyShowUnallocatedDevicesFilter, setOnlyShowUnallocatedDevicesFilter] = useState(false);

  const {
    isLoading,
    deviceSubscriptions,
    filteredDeviceSubscriptions,
    subscriptionExpirationSummary,
    organizationSubscriptionSummary,
  } = useQuickAndDirtySubscriptionQuery({
    showNeedsAttentionDevicesFilter,
    onlyShowUnallocatedDevicesFilter,
  });

  // render provider children wrapped with loading indicator
  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <DevicesAndBillingContext.Provider
      value={{
        actions: {
          toggleSubscriptionExpiredFilter: (toggle) => {
            setShowNeedsAttentionDevicesFilter(toggle);
          },
          toggleUnallocatedDevicesFilter: () => {
            setOnlyShowUnallocatedDevicesFilter(!onlyShowUnallocatedDevicesFilter);
          },
        },

        state: {
          deviceSubscriptions,
          filteredDeviceSubscriptions,
          subscriptionExpirationSummary,
          showNeedsAttentionDevicesFilter,
          onlyShowUnallocatedDevicesFilter,
          organizationSubscriptionSummary,
        },
      }}
    >
      {children}
    </DevicesAndBillingContext.Provider>
  );
};

export default DevicesAndBillingSummaryProvider;
