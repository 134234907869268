export const datadogConfig = {
  applicationId: '52a2868e-8c4b-4674-9d00-edbe65dbddf0',
  clientToken: 'pub5931acc06d7495ed291bd1266c7215f6',
  site: 'datadoghq.com',
  service: 'prod-web-front-end',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '3.2.0.2025-02-12-15-31-24-prod',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackFrustrations: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
};
export const datadogLogsConfig = {
  clientToken: 'pub5931acc06d7495ed291bd1266c7215f6',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleMessagesToLogs: true,
  sessionSampleRate: 100,
};
