import { useState } from 'react';

import { getDashboardAnalytics } from 'legacy/core/api/analytics';
import { NoDataDiv } from 'legacy/shared/v1/styles/components/Analytics';
import { FormCol } from 'legacy/shared/v1/styles/components/Form';
import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/FilteredDropdown';
import {
  formatISOTimestampToShortDate,
  getAWeekAgo,
  getYesterday,
} from 'legacy/shared/v1/utilities/time';
import { validateAnalyticsDateRange } from 'legacy/shared/v1/utilities/validators';
import { useSelector } from 'react-redux';
import DateRangeSubmitForm from 'legacy/shared/v1/components/DateRangeSubmitForm';

const AnalyticsDashboardQueryFilter = ({
  groups,
  vehicles,
  setDashboardQueryResults,
  setStartAndEndDate,
  setStartDate,
  setEndDate,
}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [serverError, setServerError] = useState(false);

  const onSubmit = async ({ startDate, endDate }) => {
    try {
      let data = await getDashboardAnalytics({
        startDate,
        endDate,
        groupId: selectedGroup ? selectedGroup.group_id : null,
        vehicleId: selectedVehicle ? selectedVehicle.vehicle_id : null,
        organizationId: filteredOrganizationId,
      });

      setDashboardQueryResults(data.response.message);
      setStartAndEndDate('_from_' + startDate + '_to_' + endDate);
      setStartDate(startDate);
      setEndDate(endDate);
      setServerError(false);
    } catch (e) {
      console.log('Analytics query failed:' + e);
      setServerError(true);
    }
  };

  const groupOptions = groups?.map((group) => ({
    label: group.group_name,
    value: group.group_name,
  }));
  const vehicleOptions = vehicles
    ?.filter((v) => {
      return selectedGroup ? selectedGroup.vehicle_id.includes(v.meta.vehicle_id) : true;
    })
    .map((v) => ({ label: v.meta.label, value: v.meta.label }));

  const defaultStartDate = formatISOTimestampToShortDate(getAWeekAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getYesterday());

  return (
    vehicles && (
      <>
        <DateRangeSubmitForm
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          submitHandler={onSubmit}
          validationFunction={validateAnalyticsDateRange}
          analyticsQuery
        >
          <FormCol noMinWidth>
            <FilteredDropdown
              title={'Groups'}
              placeholderLabel={'All Groups'}
              dropdownOptions={groupOptions}
              handleItemSelect={(selectedGroup) => {
                setSelectedGroup(
                  selectedGroup ? groups.find((g) => g.group_name === selectedGroup.label) : null,
                );
              }}
              defaultItem={null}
              styleOptions={{ restrictHeight: false }}
            />
          </FormCol>
          <FormCol noMinWidth>
            <FilteredDropdown
              title={'Vehicle'}
              placeholderLabel={'All Vehicles'}
              dropdownOptions={vehicleOptions}
              handleItemSelect={(selectedVehicle) => {
                setSelectedVehicle(
                  selectedVehicle
                    ? vehicles.find((v) => v.meta.label === selectedVehicle.label)
                    : null,
                );
              }}
              defaultItem={null}
              styleOptions={{ restrictHeight: false }}
            />
          </FormCol>
        </DateRangeSubmitForm>
        {serverError && (
          <NoDataDiv analytics>Server error: No message was returned from the server</NoDataDiv>
        )}
      </>
    )
  );
};

export default AnalyticsDashboardQueryFilter;
