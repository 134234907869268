import { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import checkIcon from 'assets/images/check@2x.png';
import {
  disassociateFSEFromOrganization,
  editOrganization,
  fetchOrganizationFSEs,
  fetchOrganizations,
  renameOrganization,
} from 'legacy/core/redux/organizations/actions';
import {
  toggleCreateUserModal,
  toggleDeactivateUserModal,
  toggleEditUserModal,
  toggleUserLimitModal,
} from 'legacy/core/redux/ui/actions';
import { selectUserRole } from 'legacy/core/redux/user/selectors';
import { activateUser, fetchFSEUsers, fetchUsers } from 'legacy/core/redux/users/actions';
import OrganizationCard from 'legacy/features/organizations/components/OrganizationCard';
import OrganizationDetailHeader from 'legacy/features/organizations/components/OrganizationDetailHeader';
import OrganizationUserActionMenu from 'legacy/features/organizations/components/OrganizationUserActionMenu';
import colors from 'legacy/shared/v1/constants/colors';
import {
  allAdminUserRoles,
  FIELD_SOLUTION_ENGINEER,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
  variableScopeAllowedUserRoles,
} from 'legacy/shared/v1/constants/users';
import useAPIData from 'legacy/shared/v1/hooks/useAPIData';
import AssociateFSEToOrganizationModal from 'legacy/shared/v1/modals/AssociateFSEToOrganizationModal';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import OrganizationForm from 'legacy/shared/v1/modals/OrganizationForm';
import { Badge } from 'legacy/shared/v1/styles/components/Badge';
import { Checkbox } from 'legacy/shared/v1/styles/components/Checkbox';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import PageListWrapper, {
  ExportDiv,
  ExportWrapper,
  TableCheckboxFilterSpan,
  TableCheckboxFilterWrapper,
} from 'legacy/shared/v1/styles/components/PageList';
import { TableSpinner } from 'legacy/shared/v1/styles/components/Spinner';
import { TableAvatar } from 'legacy/shared/v1/styles/components/TableAvatar';
import {
  TruncatedText,
  TruncatedTextWithWrap,
} from 'legacy/shared/v1/styles/components/TruncatedText';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import SortableTableWithoutPagination from 'legacy/shared/v1/ui/table/SortableTableWithoutPagination';
import TabView from 'legacy/shared/v1/ui/tabs/TabView';
import { sizes } from 'legacy/shared/v1/utilities/media';
import { validateOrganization } from 'legacy/shared/v1/utilities/validators';
import UserListExportButton from 'legacy/features/organizations/components/buttons/UserListExportButton';
import { TableTab } from 'legacy/shared/v1/styles/components/Tabs';

const OrganizationDetailPage = ({
  userRole,
  users,
  currentUserUsername,
  fetchOrganizations,
  fetchUsers,
  fetchFSEUsers,
  toggleCreateUserModal,
  toggleEditUserModal,
  toggleDeactivateUserModal,
  activateUser,
  editOrganization,
  fetchOrganizationFSEs,
  disassociateFSEFromOrganization,
}) => {
  const organizations = useSelector((state) => state.organizations.organizations);
  const { organizationId } = useParams();

  const organization = organizations.find(
    (organization) => organization.organizationId === decodeURIComponent(organizationId),
  );

  const [activeTab, setActiveTab] = useState('users');
  const [addFSEModalActive, setAddFSEModalActive] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [disabledUsersFilterIsChecked, setDisabledUsersFilterIsChecked] = useState(true);
  const [listingCount, setListingCount] = useState(organizationUsers.length);
  const organizationsLoaded = useAPIData({ fetchAPIData: fetchOrganizations });
  const [FSETableUsers, setFSETableUsers] = useState([]);
  useEffect(() => {
    let fseUsers = organization.associatedFSE
      ? organization.associatedFSE.filter(
          (user) => user.user_role === userRoles[FIELD_SOLUTION_ENGINEER].value,
        )
      : [];
    setFSETableUsers(fseUsers);
  }, [organization.associatedFSE]);

  const fsesLoaded = useAPIData({
    fetchAPIData: async () => {
      if (activeTab === 'fses' && organizationsLoaded) {
        const fsesSuccess = await fetchFSEUsers();
        return fsesSuccess;
      }
      return;
    },
    dependencies: [addFSEModalActive],
  });
  const usersLoaded = useAPIData({
    fetchAPIData: async () => {
      if (organizationsLoaded) {
        const organizationId = variableScopeAllowedUserRoles.includes(userRole)
          ? organization.organizationId
          : '';
        const usersSuccess = await fetchUsers(organizationId);
        return usersSuccess;
      }
    },
    dependencies: [organizationsLoaded],
  });
  const organizationFSEsLoaded = useAPIData({
    fetchAPIData: async () => {
      if (activeTab === 'fses' && organizationsLoaded) {
        const organizationId = variableScopeAllowedUserRoles.includes(userRole)
          ? organization.organizationId
          : '';
        const organizationFSEsSuccess = await fetchOrganizationFSEs(organizationId);
        return organizationFSEsSuccess;
      }
    },
    dependencies: [organizationsLoaded, activeTab === 'fses'],
  });

  const handleToggleEditModal = ({ username, organizationId }) => {
    return (event) => {
      event.preventDefault();
      toggleEditUserModal({ username, organizationId });
    };
  };

  const handleDeactivateUser = (user) => {
    if (organization) {
      return toggleDeactivateUserModal({
        username: user.username,
        organizationId: organization.organizationId,
      });
    }
  };

  const handleActivateUser = ({ user, organization }) => {
    if (organization) {
      return (event) => {
        event.preventDefault();
        activateUser(user);
      };
    }
  };

  const handleDisassociateFSE = ({ username, organizationId }) => {
    return (event) => {
      disassociateFSEFromOrganization({ username, organizationId });
    };
  };

  const defaultModalState = {
    isOpen: false,
    mode: null,
    organization: null,
    confirmHandler: null,
  };

  const [organizationModal, updateOrganizationModalProps] = useState(defaultModalState);

  const handleCloseModal = () => updateOrganizationModalProps({ defaultModalState });

  const handleOpenEditModal = (event, organizationId, user) => {
    event.preventDefault();
    const organization = organizations.find(
      (organization) => organization.organizationId === organizationId,
    );

    updateOrganizationModalProps({
      organization,
      isOpen: true,
      mode: OrganizationForm.MODE.EDIT,
      confirmHandler: editOrganization,
    });
  };

  const usersByOrg = users.filter((u) =>
    organization ? u.organizationId === organization.organizationId : [],
  );

  useEffect(() => {
    setOrganizationUsers(
      usersByOrg.filter((user) => user.user_role !== userRoles[SYSTEM_ADMIN].value),
    );
  }, [users]);

  useEffect(() => {
    setListingCount(organizationUsers.length);
  }, [organizationUsers]);

  useEffect(() => {
    if (disabledUsersFilterIsChecked) {
      setOrganizationUsers(usersByOrg.filter((u) => u.active === true));
    } else {
      setOrganizationUsers(usersByOrg);
    }
  }, [disabledUsersFilterIsChecked, users]);
  const columns = [
    {
      title: '',
      displayProperty: 'picture',
      className: 'picture',
      width: '1%',
      searchEnabled: false,
      render({ rowData }) {
        return (
          <span className="userNameAndIcon">
            {rowData.loadingStatus ? (
              <TableSpinner className="spinner-margin" />
            ) : (
              <TableAvatar profilePicture={rowData.picture} />
            )}
          </span>
        );
      },
    },
    {
      title: 'Username',
      displayProperty: 'username',
      className: 'username',
      render({ rowData, cellJsx }) {
        return (
          <span style={styles.primaryCell}>
            <TruncatedText userName title={'Username: ' + rowData.username}>
              {cellJsx}
            </TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'First Name',
      displayProperty: 'givenName',
      className: 'firstName',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap
            orgTable
            title={'First Name: ' + rowData.givenName}
            style={styles.primaryCell}
          >
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Last Name',
      displayProperty: 'familyName',
      className: 'lastName',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap
            orgTable
            title={'Last Name: ' + rowData.familyName}
            style={styles.primaryCell}
          >
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Title',
      displayProperty: 'userTitle',
      className: 'title',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap
            orgTable
            title={'Title: ' + rowData.userTitle}
            style={styles.secondaryCell}
          >
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Contact Email',
      displayProperty: 'email',
      className: 'email',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap
            orgTable
            title={'Contact Email: ' + rowData.email}
            style={styles.secondaryCell}
          >
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Role',
      displayProperty: 'role',
      className: 'role',
      render({ rowData, cellJsx }) {
        return (
          <span title={'Role: ' + rowData.role} style={styles.secondaryCell}>
            {cellJsx ? cellJsx : ''}
          </span>
        );
      },
    },
    {
      title: 'Status',
      displayProperty: 'status',
      key: 'status',
      render({ rowData, cellJsx }) {
        return (
          <Badge active={rowData.active === true ? true : false}>
            <span title="Status">{cellJsx}</span>
          </Badge>
        );
      },
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) => {
        return (
          <OrganizationUserActionMenu
            user={rowData}
            currentUserUsername={currentUserUsername}
            activeTab={activeTab}
            organization={organization}
            userRole={userRole}
            handleToggleEditModal={handleToggleEditModal}
            handleDeactivateUser={handleDeactivateUser}
            handleActivateUser={handleActivateUser}
            handleDisassociateFSE={handleDisassociateFSE}
          />
        );
      },
    },
  ];
  console.log('FSESTATE', FSETableUsers);
  return organizationsLoaded && usersLoaded ? (
    <PageListWrapper>
      <OrganizationDetailHeader
        resource={organization}
        resourceNameKey={'organizationName'}
        resourceIdKey={'organizationId'}
        resourceName={'Organization'}
        resourcePath={'organizations'}
        validator={validateOrganization}
        submitHandler={editOrganization}
        createResourceAccessRoles={
          activeTab === 'users' ? allAdminUserRoles : [userRoles[SYSTEM_ADMIN].value]
        }
        createResourceButtonText={
          activeTab === 'users' ? 'Create User' : 'Add Field Solution Engineer'
        }
        toggleCreateResourceModal={
          activeTab === 'users'
            ? toggleCreateUserModal
            : () => () => setAddFSEModalActive(organization.organizationId)
        }
        editResource={editOrganization}
        handleOpenEditModal={handleOpenEditModal}
      />

      <TabView
        tabs={[
          {
            tabElement: <TableTab key="Users">Users</TableTab>,
            handleClick: () => {
              setActiveTab('users');
            },
            content: (
              <Media
                queries={{
                  tablet: { maxWidth: sizes.tablet },
                  mobile: { maxWidth: sizes.mobile },
                }}
              >
                {(matches) =>
                  matches.tablet ? (
                    <div>
                      <h5>
                        Total of {listingCount} User
                        {listingCount !== 1 ? 's' : ''}
                      </h5>
                      <TableCheckboxFilterWrapper>
                        <Checkbox singleCheck>
                          <CheckboxInner
                            isChecked={disabledUsersFilterIsChecked}
                            onClick={() =>
                              setDisabledUsersFilterIsChecked(!disabledUsersFilterIsChecked)
                            }
                          />
                        </Checkbox>
                        <TableCheckboxFilterSpan>Hide disabled users</TableCheckboxFilterSpan>
                      </TableCheckboxFilterWrapper>
                      <Cards>
                        {organizationUsers
                          .filter((user) => user.user_role !== userRoles[SYSTEM_ADMIN].value)
                          .map((user) => (
                            <OrganizationCard
                              user={user}
                              isFSE={false}
                              currentUserUsername={currentUserUsername}
                              activeTab={activeTab}
                              organization={organization}
                              userRole={userRole}
                              handleToggleEditModal={handleToggleEditModal}
                              handleDeactivateUser={handleDeactivateUser}
                              handleActivateUser={handleActivateUser}
                              handleDisassociateFSE={handleDisassociateFSE}
                            />
                          ))}
                      </Cards>
                    </div>
                  ) : (
                    <div style={styles.vehiclesBody}>
                      <ExportWrapper>
                        <ExportDiv>
                          <UserListExportButton
                            users={organizationUsers}
                            organizationName={organization.organizationName}
                          />
                        </ExportDiv>
                      </ExportWrapper>
                      <h5>
                        {listingCount === organizationUsers?.length
                          ? `Total of ${listingCount} User${listingCount !== 1 ? 's' : ''}`
                          : `${listingCount} of ${organizationUsers?.length} User${
                              organizationUsers?.length !== 1 ? 's' : ''
                            }`}
                      </h5>

                      <TableCheckboxFilterWrapper>
                        <Checkbox singleCheck>
                          <CheckboxInner
                            isChecked={disabledUsersFilterIsChecked}
                            onClick={() =>
                              setDisabledUsersFilterIsChecked(!disabledUsersFilterIsChecked)
                            }
                          />
                        </Checkbox>
                        <TableCheckboxFilterSpan>Hide disabled users</TableCheckboxFilterSpan>
                      </TableCheckboxFilterWrapper>
                      <WcpTable
                        columns={columns}
                        tableSourceData={organizationUsers}
                        setListingCount={setListingCount}
                        defaultSortProperty="username"
                        emptyText="No users are currently associated with this organization"
                      />
                    </div>
                  )
                }
              </Media>
            ),
          },
          {
            tabElement: (
              <TableTab key="Field Solution Engineers">Field Solution Engineers</TableTab>
            ),
            handleClick: () => {
              setActiveTab('fses');
            },
            content: (
              <Media
                queries={{
                  tablet: { maxWidth: sizes.tablet },
                  mobile: { maxWidth: sizes.mobile },
                }}
              >
                {(matches) =>
                  matches.tablet ? (
                    <div>
                      {organization.associatedFSE && (
                        <Cards>
                          {FSETableUsers?.map((user) => (
                            <OrganizationCard
                              user={user}
                              isFSE={true}
                              currentUserUsername={currentUserUsername}
                              activeTab={activeTab}
                              organization={organization}
                              userRole={userRole}
                              handleToggleEditModal={handleToggleEditModal}
                              handleDeactivateUser={handleDeactivateUser}
                              handleActivateUser={handleActivateUser}
                              handleDisassociateFSE={handleDisassociateFSE}
                            />
                          ))}
                        </Cards>
                      )}
                    </div>
                  ) : (
                    <div style={styles.vehiclesBody}>
                      <WcpTable
                        columns={[
                          columns[0],
                          columns[1],
                          columns[2],
                          columns[3],
                          columns[5],
                          columns[8],
                        ]}
                        tableSourceData={FSETableUsers}
                        setListingCount={setListingCount}
                        defaultSortProperty="username"
                        emptyText="No Field Solution Engineers are currently associated with this organization"
                      />
                    </div>
                  )
                }
              </Media>
            ),
          },
        ]}
      />
      {activeTab !== 'fses' ? null : organizationsLoaded && organizationFSEsLoaded ? null : (
        <LoadingOverlay />
      )}
      {organizationModal.isOpen && (
        <ModalPortal onRequestClose={handleCloseModal}>
          <OrganizationForm
            mode={organizationModal.mode}
            organization={organizationModal.organization}
            confirmHandler={organizationModal.confirmHandler}
            userRole={userRole}
          />
        </ModalPortal>
      )}
      {addFSEModalActive ? (
        fsesLoaded ? (
          <ModalPortal onRequestClose={() => setAddFSEModalActive(false)}>
            <AssociateFSEToOrganizationModal
              cancelText="Cancel"
              cancelHandler={() => setAddFSEModalActive(false)}
              onSuccess={() => setAddFSEModalActive(false)}
              FSEs={users.filter(
                (user) =>
                  user.userRole === userRoles[FIELD_SOLUTION_ENGINEER].value &&
                  !organization.associatedFSE.find((FSE) => FSE.username === user.username),
              )}
              organizationId={organization.organizationId}
            />
          </ModalPortal>
        ) : (
          <LoadingOverlay />
        )
      ) : null}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  (state, ownProps) => ({
    currentUserUsername: state.user.user.username,
    users: state.users.users,
    userRole: selectUserRole(state.user.user),
  }),
  (dispatch) => ({
    fetchOrganizations: (organizationId) => {
      const organizationIds = organizationId ? [organizationId] : [];
      return dispatch(fetchOrganizations({ organizationIds }));
    },
    fetchOrganizationFSEs: (organizationId) => dispatch(fetchOrganizationFSEs(organizationId)),
    fetchUsers: (organizationId) => dispatch(fetchUsers({ organizationId })),
    fetchFSEUsers: () => dispatch(fetchFSEUsers()),
    activateUser: (user) => dispatch(activateUser(user)),
    toggleDeactivateUserModal: ({ username, organizationId }) =>
      dispatch(toggleDeactivateUserModal({ username, organizationId })),
    toggleUserLimitModal: () => dispatch(toggleUserLimitModal()),
    editOrganization: (organization) => dispatch(editOrganization(organization)),
    renameOrganization: async ({ organization, newOrganizationName }) => {
      return await dispatch(renameOrganization({ organization, newOrganizationName }));
    },
    toggleCreateUserModal: (organizationId) => () =>
      dispatch(toggleCreateUserModal({ organizationId })),
    toggleEditUserModal: ({ username, organizationId }) =>
      dispatch(toggleEditUserModal({ username, organizationId })),
    disassociateFSEFromOrganization: ({ username, organizationId }) =>
      dispatch(disassociateFSEFromOrganization({ username, organizationId })),
  }),
)(OrganizationDetailPage);

const styles = {
  tableHead: {
    marginBottom: 57,
    display: 'flex',
    flexWrap: 'wrap',
  },
  primaryCell: {
    color: colors.midnight,
  },
  secondaryCell: {
    color: colors.haze,
  },
  tableHeadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 0 100%',
  },
  tableDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 0 100%',
    marginTop: '20px',
  },
  organizationDetail: {
    flex: '0 1 18%',
    fontSize: '14px',
    textAlign: 'center',
    color: colors.haze,
  },
  organizationDetailLast: {
    flex: '0 1 18%',
    fontSize: '14px',
    color: colors.haze,
    textAlign: 'right',
  },
  organizationDetailFirst: {
    textAlign: 'left',
  },
  organizationDetailId: {
    flex: '0 1 18%',
    fontSize: '14px',
    textAlign: 'left',
    color: colors.midnight,
  },
  organizationName: {
    display: 'inline-block',
  },
  organizationNameIcon: {
    marginBottom: 7,
    marginRight: 15,
  },
  createUserButton: {
    position: 'relative',
    display: 'inline-block',
    border: 'none',
    height: 34,
    borderRadius: 17,
    background: `${colors.midnight}`,
    padding: '10px 42px 10px 14px',
    fontSize: 11,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '0.11em',
    color: 'white',
    transition: 'background .3s',
  },
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  editNameIcon: {
    marginLeft: 30,
    marginBottom: 7,
    cursor: 'pointer',
  },
  nameInput: {
    display: 'none',
    border: 'none',
    borderBottom: '1px solid black',
    backgroundColor: colors.fog,
    fontSize: 24,
    fontWeight: 700,
  },

  checkboxHolderImage: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    width: 14,
    height: 14,
    marginRight: 20,
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
  },
  checkboxHolderImageSelected: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
    width: 14,
    height: 14,
    marginRight: 20,
    border: 'none',
    backgroundImage: `url(${checkIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};
