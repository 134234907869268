import React, { useState } from 'react';
import { TableTabs } from 'legacy/shared/v1/styles/components/Tabs';

export default ({ styleProp, tabs, portalNode }) => {
  const configurationsTableNav = styleProp === 'configurationsTableNav';
  const vehicleDeviceTable = styleProp === 'vehicleDeviceTable';
  console.log(styleProp);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabElements = tabs.map((tab, index) => {
    const handleClick = () => {
      tab.handleClick && tab.handleClick();
      setSelectedIndex(index);
    };
    return tab.tabElement ? (
      React.cloneElement(tab.tabElement, {
        onClick: handleClick,
        active: selectedIndex === index,
      })
    ) : (
      <div onClick={handleClick}>{tab.label}</div>
    );
  });
  return (
    <>
      <TableTabs
        configurationsTableNav={configurationsTableNav}
        vehicleDeviceTable={vehicleDeviceTable}
      >
        {tabElements}
      </TableTabs>
      <div style={styles.contentContainer}>{tabs ? tabs[selectedIndex].content : null}</div>
    </>
  );
};

const styles = {
  contentContainer: {
    overflow: 'visible',
  },
};
