import { vehicleValidators } from 'legacy/features/vehicles/validators/vehicleValidators';

export const validateVehicle = ({ values, validDuids }) => {
  const labelErrors = vehicleValidators.label(values.label, 36);
  const vinErrors = vehicleValidators.vin(values.vin.toUpperCase());
  const typeErrors = vehicleValidators.type(values.type);
  const makeErrors = vehicleValidators.make(values.make, 128);
  const modelErrors = vehicleValidators.model(values.model, 128);
  const licensePlateErrors = vehicleValidators.licensePlate(values.licensePlate, 12);
  const licenseStateErrors = vehicleValidators.licenseState(values.licenseState);
  let vsgDuidErrors = null;

  if (values.duid && !validDuids?.includes(values.duid.replace(/-/g, ''))) {
    vsgDuidErrors = 'Please enter a valid VSG ID from your subscription';
  }
  let subscriptionTypeErrors = null;
  if (values.duid && (!values.subscriptionType || values.subscriptionType === 'UNALLOCATED')) {
    subscriptionTypeErrors = 'Please choose a subscription type';
  }
  const yearErrors = vehicleValidators.year(values.year);
  const notesErrors = vehicleValidators.notes(values.notes, 1000);
  const aliasErrors = vehicleValidators.alias(values.alias, 36);

  return {
    ...(labelErrors ? { label: labelErrors } : {}),
    ...(vinErrors ? { vin: vinErrors } : {}),
    ...(typeErrors ? { type: typeErrors } : {}),
    ...(makeErrors ? { make: makeErrors } : {}),
    ...(modelErrors ? { model: modelErrors } : {}),
    ...(licensePlateErrors ? { licensePlate: licensePlateErrors } : {}),
    ...(licenseStateErrors ? { licenseState: licenseStateErrors } : {}),
    ...(vsgDuidErrors ? { vsgDuid: vsgDuidErrors } : {}),
    ...(subscriptionTypeErrors ? { subscriptionType: subscriptionTypeErrors } : {}),
    ...(yearErrors ? { year: yearErrors } : {}),
    ...(notesErrors ? { notes: notesErrors } : {}),
    ...(aliasErrors ? { alias: aliasErrors } : {}),
  };
};
