import { SimpleDropdownItem } from 'legacy/shared/v1/styles/components/DropdownMenu';
import ActionMenu from 'legacy/shared/v1/ui/table/ActionMenu';
import ViewDeviceLogsMenuItem from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/ViewDeviceLogsMenuItem';

const DevicesAndBillingActionMenu = ({ vsgDuid, id }) => {
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <ViewDeviceLogsMenuItem vsgDuid={vsgDuid} id="viewDeviceLogsMenuItem" />
        </SimpleDropdownItem>
      </ActionMenu>
    </>
  );
};

export default DevicesAndBillingActionMenu;
