import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { timeZoneOptions } from 'legacy/features/geofences/geofences/constants/dropdownOptions';
import { FormAsterisk, FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';
import {
  EndDateDiv,
  EndTimeOptionDiv,
  ExpiresAtDiv,
  GeofenceExpiredMessage,
  TimeDiv,
  TimeZoneDiv,
} from 'legacy/shared/v1/styles/components/Geofences';
import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/FilteredDropdown';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';
import StyledDayPickerInput from 'legacy/shared/v1/ui/StyledDayPickerInput';
import { getTimeOptionsIn15MinuteIncrements } from 'legacy/shared/v1/utilities/time';

let timeOptions = getTimeOptionsIn15MinuteIncrements();

const dropdownTimeOptions = timeOptions.map((t) => ({ label: t, value: t })) || [];

const GeofenceExpiresAtMapCard = ({
  expiresAtDate,
  expiresAtTime,
  timeZone,
  expirationTime,
  duration,
  setGeofenceCardData,
}) => {
  const handleEndTimeSelect = (label) => {
    if (label) {
      setGeofenceCardData((pgf) => ({ ...pgf, expiresAtTime: label?.label }));
    } else {
      return null;
    }
  };

  const [isExpiredMessage, setIsExpiredMessage] = useState(null);

  useEffect(() => {
    if (expirationTime) {
      let expiration = DateTime.fromISO(expirationTime);
      let now = DateTime.now().toUTC();
      if (now > expiration) {
        setIsExpiredMessage('This geofence has passed the expiration and is currently disabled');
      } else {
        setIsExpiredMessage(null);
      }
    }
  }, [duration, expirationTime]);

  return (
    <>
      <ExpiresAtDiv>
        <EndDateDiv>
          <FormLabelAnimated animated>
            End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
          </FormLabelAnimated>
          <>
            <StyledDayPickerInput
              placeholder={expiresAtDate}
              value={expiresAtDate}
              onDayChange={(day) => {
                setGeofenceCardData((pgf) => ({
                  ...pgf,
                  expiresAtDate: DateTime.fromJSDate(day).toLocaleString(),
                }));
              }}
            />
          </>
        </EndDateDiv>

        <TimeDiv>
          <EndTimeOptionDiv>
            <FilteredDropdown
              title={'End Time'}
              dropdownOptions={dropdownTimeOptions}
              handleItemSelect={(label) => {
                handleEndTimeSelect(label);
              }}
              styleOptions={{
                narrowWidth: true,
                hideClearX: true,
                required: true,
              }}
              defaultItem={null}
              placeholderLabel={expiresAtTime ? expiresAtTime : timeOptions[0]}
            />
          </EndTimeOptionDiv>
        </TimeDiv>
        <TimeZoneDiv>
          <FormLabelAnimated animated>
            Time Zone<FormAsterisk>*</FormAsterisk>
          </FormLabelAnimated>
          <StyledReactSelect
            geofenceCard
            mediumTextMobile
            name="timeZone"
            disabled={false}
            isClearable={false}
            isSearchable={false}
            options={timeZoneOptions}
            key={`timeZoneSelect${timeZone}`}
            defaultValue={{
              label: timeZoneOptions.find((t) => t.value === timeZone)?.label,
            }}
            onChange={({ value }) => {
              setGeofenceCardData((pgf) => ({ ...pgf, timeZone: value }));
            }}
            field="true"
          />
        </TimeZoneDiv>
      </ExpiresAtDiv>
      <GeofenceExpiredMessage>{isExpiredMessage}</GeofenceExpiredMessage>
    </>
  );
};

export default GeofenceExpiresAtMapCard;
