import React from 'react';

import TabView from 'legacy/shared/v1/ui/tabs/TabView';
import { TableTab } from 'legacy/shared/v1/styles/components/Tabs';

import SortableTableWithoutPagination from 'legacy/shared/v1/ui/table/SortableTableWithoutPagination';
import {
  uidColumn,
  firmwareChannelColumn,
  configurationColumn,
  deviceNameColumn,
} from 'legacy/shared/v1/utilities/vehicle';

import colors from 'legacy/shared/v1/constants/colors';
import { getCurrentVersionColumn } from 'legacy/shared/v1/utilities/vehicle';

const tablePaddingLeft = 30;
const tablePaddingRight = 30;

export const VehicleDeviceTable = ({
  otaConfigEditPermissions,
  otaFirmwareEditPermissions,
  firmwareChannels,
  categorizedDevices,
  configurations,
  generateHandleSelectChange,
}) => {
  let firmwareChannelColumnContent = firmwareChannelColumn(
    otaFirmwareEditPermissions,
    firmwareChannels,
    generateHandleSelectChange,
    204,
    0,
    tablePaddingRight,
  );

  return (
    <div>
      {
        <TabView
          styleProp="vehicleDeviceTable"
          tabs={[
            {
              tabElement: (
                <TableTab key="vsgRegistrationID" vehicleDeviceTable>
                  VSG
                </TableTab>
              ),
              content: (
                <SortableTableWithoutPagination
                  style={{
                    overflow: 'visible',
                    marginLeft: tablePaddingLeft && `-${tablePaddingLeft}px`,
                    marginRight: tablePaddingRight && `-${tablePaddingRight}px`,
                  }}
                  columns={[
                    deviceNameColumn(tablePaddingLeft, 272),
                    uidColumn(10, 204),
                    firmwareChannelColumnContent,
                    getCurrentVersionColumn(tablePaddingLeft),
                  ]}
                  data={categorizedDevices.connectivityModule}
                  onRow={(row, index) => ({
                    style: {
                      maxHeight: '50px',
                      backgroundColor: index % 2 === 0 ? colors.fog : colors.concrete,
                    },
                  })}
                  emptyText="No VSGs are currently associated with this vehicle"
                />
              ),
            },
            {
              tabElement:
                categorizedDevices.cenComCore &&
                categorizedDevices.cenComCore.length > 0 &&
                categorizedDevices.cenComCore[0].product_name ? (
                  <TableTab key="cenComCore" vehicleDeviceTable>
                    {categorizedDevices.cenComCore[0].product_name}
                  </TableTab>
                ) : (
                  <TableTab key="cenComCore" vehicleDeviceTable>
                    Control System
                  </TableTab>
                ),
              content: (
                <SortableTableWithoutPagination
                  style={{
                    overflow: 'visible',
                    marginLeft: tablePaddingLeft && `-${tablePaddingLeft}px`,
                    marginRight: tablePaddingRight && `-${tablePaddingRight}px`,
                  }}
                  data={
                    categorizedDevices.cenComCore && categorizedDevices.cenComCore[0]?.product_name
                      ? categorizedDevices.cenComCore
                      : []
                  }
                  columns={[
                    uidColumn(tablePaddingLeft, 272),
                    firmwareChannelColumnContent,
                    getCurrentVersionColumn(),
                    configurationColumn(
                      otaConfigEditPermissions,
                      configurations,
                      generateHandleSelectChange,
                      204,
                      tablePaddingRight,
                    ),
                  ]}
                  onRow={(row, index) => ({
                    style: {
                      maxHeight: '50px',
                      backgroundColor: index % 2 === 0 ? colors.fog : colors.concrete,
                    },
                  })}
                  emptyText={`No ${
                    categorizedDevices.cenComCore &&
                    categorizedDevices.cenComCore.length > 0 &&
                    categorizedDevices.cenComCore[0].product_name
                      ? 'CenCom Cores are'
                      : 'Control System is'
                  } currently associated with this vehicle`}
                />
              ),
            },
            {
              tabElement: (
                <TableTab key="peripherals" vehicleDeviceTable>
                  Peripherals
                </TableTab>
              ),
              content: (
                <SortableTableWithoutPagination
                  style={{
                    overflow: 'visible',
                    marginLeft: tablePaddingLeft && `-${tablePaddingLeft}px`,
                    marginRight: tablePaddingRight && `-${tablePaddingRight}px`,
                  }}
                  columns={[
                    deviceNameColumn(tablePaddingLeft, 272),
                    uidColumn(10, 204),
                    firmwareChannelColumnContent,
                    getCurrentVersionColumn(),
                  ]}
                  data={categorizedDevices.peripherals}
                  onRow={(row, index) => ({
                    style: {
                      maxHeight: '50px',
                      backgroundColor: index % 2 === 0 ? colors.fog : colors.concrete,
                    },
                  })}
                  emptyText="No peripherals are currently associated with this vehicle"
                />
              ),
            },
          ]}
        />
      }
    </div>
  );
};
